<template>
  <page-view bg-color="#f5f5f5">
    <template v-slot:nav>
      <nav-bar title="添加职位"></nav-bar>
    </template>
    <van-cell-group>
      <van-field label="职位名称" clearable v-model="formData.userName" input-align="right" :placeholder="inputPlaceholder" />
      <van-cell title="配置权限" clickable is-link @click="linkToConfig" />
    </van-cell-group>
    <van-button color="#E4AF79" class="mt-30 submit-btn" block @click="submit()">保存</van-button>
    <template v-slot:routerView>
      <router-view></router-view>
    </template>
  </page-view>
</template>
<style lang="scss" scoped>
  @import './index.scss';
</style>
<script>
import PageView from '@/layouts/PageView'
import NavBar from '@/components/nav-bar'
import { Toast, Cell, CellGroup, Button, Field } from 'vant'
import FormModel from '@/model/permission/add-position/form'

export default {
  data () {
    const formModel = new FormModel()
    return {
      formModel,
      formData: formModel.formData,
      point: true,
      inputPlaceholder: '必填'
    }
  },
  components: {
    PageView,
    NavBar,
    [Toast.name]: Toast,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Button.name]: Button,
    [Field.name]: Field
  },
  created () {
    this.$store.dispatch('permission/cleanPositionObj') // clean
    this.$store.dispatch('permission/cleanPermissionConfig') // clean
    this.$store.dispatch('permission/loadPermissionConfig')
  },
  mounted () {
  },
  beforeDestroy () {
  },
  methods: {
    async submit () {
      if (!this.formData.userName) {
        Toast('请输入职位名称')
        return
      }
      this.formData.permissionIds = await this.$store.dispatch('permission/permissionIds')
      this.formModel.submit().then(res => {
        this.$store.dispatch('permission/loadSelectList')
        this.$router.back()
      })
    },
    linkToConfig () {
      this.$router.push(`${this.$route.path}/config`)
    }
  },
  computed: {},
  watch: {}
}
</script>
